<template>
  <div>
    <a-modal
        :width="800"

        :destroyOnClose="true"
        :confirm-loading="confirmLoading"
        :dialog-style="{ top: '20px' }"
        :visible="visible" :title="title" okText="Submit" @ok="handleOk" @cancel="cancelForm">
      <a-form layout='vertical' :form="form" @submit="handleOk">
        <a-row  :gutter="[24, 24]" >
          <a-col :span="16">
        <a-form-item label='Seller Name'>
          <a-input
              placeholder="Seller Name"
              v-decorator="[
              'title',
               { rules: [{ required: true, message: 'Seller Name is required' }] ,
               initialValue:selectedItem?selectedItem.title:'',
              }
            ]"
          />
        </a-form-item>
          </a-col>
            <a-col :span="8">
            <a-form-item label='Short Code'>
          <a-input
              placeholder="Like TM for Ticket Master"
              v-decorator="[
              'shortCode',
               { rules: [{ required: true, message: 'Short Code is required' },
                         { max: 2, message: 'Short Code must not be greater than 2 characters.' },
               ] ,
               initialValue:selectedItem?selectedItem.shortCode:'',
              }
            ]"
          />
        </a-form-item>
            </a-col>
        </a-row>
        <a-row  :gutter="[24, 24]" >
          <a-col :span="12">
          <a-form-item label='Pick Background Color' :span="12">
          <ColorPickerInput
              v-model="backgroundColour"
              class="form-group"
              :style-picker-position="{ right: '5px' }"
              style-padding-picker="padding-right: 35px"
          />
        </a-form-item>
          </a-col>
          <a-col :span="12">
            <a-form-item label='Pick Text Color' :span="12">
              <ColorPickerInput
                  v-model="textColour"
                  class="form-group"
                  :style-picker-position="{ right: '5px' }"
                  style-padding-picker="padding-right: 35px"
              />
            </a-form-item>
          </a-col>
        </a-row>
        <a-form-item label='Physical Location'>
          <a-input
              :auto-size="{ minRows: 4, maxRows: 8 }"
                  type='textarea'
              placeholder="Enter full address"
              v-decorator="['location',{
                initialValue:selectedItem?selectedItem.location:'',
              }]"
          />
        </a-form-item>
        <a-form-item label='Website URL'>
          <a-input
              :auto-size="{ minRows: 3, maxRows: 6 }"
                  type='textarea'
              placeholder="Enter full url like: https://www.abccompany.com"
              v-decorator="['siteUrl',{
                initialValue:selectedItem?selectedItem.siteUrl:'',
              }]"
          />
        </a-form-item>
        <a-form-item class='collection-create-form_last-form-item'>
          <a-radio-group
              v-decorator="[
              'status',
              {
                initialValue: selectedItem&&selectedItem.status?selectedItem.status.toString():'true',
              }
            ]"
          >
            <a-radio value='true'>Active</a-radio>
            <a-radio value='false'>In-active</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>
<script>
import ColorPickerInput from "../components/ColorPickerInput";

export default {
  props: ['editMode', 'visible', 'selectedItem'],
  components: {
    ColorPickerInput
  },
  data() {
    return {
      url: process.env.VUE_APP_SERVER_URL,
      fileList: [],
      confirmLoading: false,
      form: this.$form.createForm(this),
      id: '',
      backgroundColour: '#1955CC',
      textColour: '#eeeeee'

      // visible: false,
    };
  },
  computed: {
    title() {
      return this.editMode ? 'Edit Seller' : 'Create New Seller'
    }
  },
  methods: {
    handleOk(e) {
      e.preventDefault();
      if (this.confirmLoading)
        return
      this.form.validateFields((err, values) => {
        if (!err) {
          const formData = {
            title: !!values.title ? values.title : '',
          siteUrl: !!values.siteUrl ? values.siteUrl : '',
          location: !!values.location ? values.location : '',
          status: values.status,
            backgroundColor: this.backgroundColour,
            textColor: this.textColour,
            shortCode: !!values.shortCode ? values.shortCode : '',
        }
          this.confirmLoading = true
          if (this.editMode) {
            this.$store.dispatch("UPDATE_TICKET_SELLER", {formData: formData, id: this.id})
                .then((res) => {
                  this.form.resetFields()
                  this.$message.success(res.data.message);
                  this.$emit('formSuccess')
                })
                .catch((err) => {
                  // this.$message.error(err.response.data.message);
                })
                .finally(() => {
                  this.confirmLoading = false
                });
          } else {
            this.$store.dispatch("CREATE_TICKET_SELLER", formData)
                .then((res) => {
                  this.fileList = []
                  this.form.resetFields()
                  this.$message.success(res.data.message);
                  this.$emit('formSuccess')
                })
                .catch((err) => {
                  // this.$message.error(err.response.data.message);
                })
                .finally(() => {
                  this.confirmLoading = false
                });

          }

        }
      });
    },

    cancelForm() {
      this.form.resetFields();
      this.confirmLoading = false;
      this.$emit('cancel')
    },
  },
  mounted() {
    if (this.editMode && Object.keys(this.selectedItem).length != 0) {
      this.id = this.selectedItem._id
      this.backgroundColour = this.selectedItem.backgroundColor
      this.textColour = this.selectedItem.textColor

    }
  }
}
</script>
<style>
.blocked {
  pointer-events: none;
  opacity: 0.5;
}
.color-picker {
  align-items: start;
}
</style>